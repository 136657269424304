// import Analytics from 'analytics'
// import mixpanelPlugin from '@analytics/mixpanel'

// var mixpanel = require('mixpanel-browser');
import mixpanel from 'mixpanel-browser'

export const baseURL = require('../../framework/src/config.js').baseURL;
let trackAutomaticEvents = true;
if (baseURL.includes('.prod.')) {
  trackAutomaticEvents = true;
}
// let analytics:any;
//  analytics = Analytics({
//   app: 'Restaurant-internal',
//   plugins: [
//     mixpanelPlugin({
//       token: 'e4cb9c22871b03a02d191d86faa1767d'
//     })
//   ]
// })

// mixpanel.init("cb247cda6e129d5babcf14585db5b6db");
mixpanel.init("cb247cda6e129d5babcf14585db5b6db",{debug:true,ignore_dnt:true});


// const mixpanel = new Mixpanel(
//   'e4cb9c22871b03a02d191d86faa1767d',
//   trackAutomaticEvents
// );
// mixpanel.init();
let actions: any = {
  // identify: (id: any) => {
  //   if (trackAutomaticEvents) {
  //     mixpanel.identify(id);
  //   }
  // },
  // alias: (alias: string, id: string) => {
  //   if (trackAutomaticEvents) {
  //     mixpanel.alias(alias, id);
  //   }
  // },
  track: (name: any, data: any) => {
    if (trackAutomaticEvents) {
      data ? mixpanel.track(name, data) : mixpanel.track(name);
      // mixpanel.track(name)
    }
  },
  
  // reset: () => {
  //   if (trackAutomaticEvents) {
  //     analytics.reset();
  //   }
  // },
  // people: {
  //   set: (props: any) => {
  //     if (trackAutomaticEvents) {
  //       analytics.getPeople().set(props);
  //     }
  //   },
  // },
};

export let AppMixpanel: any = actions;
