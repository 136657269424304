Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.otherIntegrationTitle =  "Other Integrations";
exports.deleteButton = "Delete";
exports.cancelButton = "Cancel";
exports.addButtonLabel = "Add";
exports.emptyText = '';
exports.requiredText = "*required";
exports.editButton = "Edit";
exports.saveButtonLabel = "Save";
exports.connectButton = "Connect";
exports.howToUseText = "How to use?";
exports.howUseLink = "https://intercom.help/engineerai/en/articles/9428031-configuring-google-tag-manger";
exports.modalTitle = "Connect Google Tag Manager";
exports.addGTMLabel = "Add GTM Script";
exports.minNumberRows = 7;
exports.deleteSuccessfullyMsg = "Deleted successfully";
exports.networkErrorMsg = "Oops! Something went wrong";
exports.adminToken = "admintoken";
exports.scriptUpdatedSuccessfullyMsg = "Script updated successfully.";
exports.scriptDeletedSuccessfullyMsg = "Script deleted successfully.";
exports.wrongDataCameFromBackend = "Invalid responce received from server";
exports.cardDescription = "Use to quickly & easily update measurement & related codes.";
exports.cardTitle = "Google Tag Manager";
exports.googleTagManagerApi = "/bx_block_analytics7/google_tag_managers/get_gtm_data";
exports.addScriptApi = "/bx_block_analytics7/google_tag_manager_configs";
exports.updateDeleteScriptApi = "/bx_block_analytics7/google_tag_manager_configs/"
// Customizable Area End