import React from "react";
// Customizable Area Start
import { Link, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Snackbar, Dialog } from "@material-ui/core";
import ProductDescriptionController, { configJSON } from "./ProductDescriptionController";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CameraAltOutlined from '@material-ui/icons/CameraAltOutlined';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import ImageGalleryNavIcon from "../../../components/src/ImageGalleryNavIcon";
import VariantPropertySelector from "../../../components/src/VariantPropertySelector";
import { Helmet } from "react-helmet";
import { Skeleton, Alert } from "@material-ui/lab";
import "../assets/style.css";
// Customizable Area End

export class ProductDescription extends ProductDescriptionController {

  // Customizable Area Start
  renderNotActiveSnackBar = () => {
    return (
      <Snackbar
        open={this.state.snackBarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snack-bar"
      >
        <Alert severity="error">
          {configJSON.productNotActive}
        </Alert>
      </Snackbar>
    )
  }

  renderAddToBag = () => {
    if (this.state.loader) {
      return <CircularProgress disableShrink className="circular-progress" />
    } else {
      return <button className="add-to-bag-button" data-test-id="addToBag" onClick={() => this.handleAddItemToCartButton()}>{this.state.AddToBagText}</button>
    }
  }
  renderSkeleton = () => {
    return (
      <div className="product-info-wrapper">
        <div className="product-images-area">
          <Skeleton variant="rect" width={560} height={600} />
        </div>
        <div className="product-details-area">
          <h1 className="product-title">
            <Skeleton variant="text" />
          </h1>
          <div className="product-price">
            <Skeleton variant="text" width={150} />
          </div>
          {["lg", "xl"].includes(this.state.screenSize) &&
            (
              <>
                <h4 className="description-title">
                  <Skeleton variant="text" width={70} />
                </h4>
                <div className="description-content">
                  <Skeleton variant="text" />
                </div>
              </>
            )}
          <div className="buttons-wrapper">
            <Skeleton variant="rect" className="skeleton-button" />
            <div className="hide-div-element" style={{
              width:
                56
            }} />
          </div>
          {["sm", "md"].includes(this.state.screenSize) && (
            <>
              <h4 className="description-title">
                <Skeleton variant="text" width={70} />
              </h4>
              <div className="description-content">
                <Skeleton variant="text" />
              </div>
            </>)}
        </div>
      </div>
    )
  }

  renderImageItem = (item: ReactImageGalleryItem, videoThumbnail: string, isThumbnail: boolean) => {
    return (
      <div style={{ position: "relative" }}>
        <div 
          style={{ display: item.original === videoThumbnail ? "flex" : "none" }} 
          className="play-icon-wrapper" 
          onClick={isThumbnail ? () => { } : this.handleVideoModal}
        >
          <PlayArrowRoundedIcon className={isThumbnail ? "play-icon-thumbnail" : "play-icon"} />
        </div>
        {isThumbnail ? (
          <img src={item.thumbnail} alt="thumbnail-image" className="image-gallery-thumbnail-image" />
        ) : (
          <img src={item.original} alt="image" className="image-gallery-image" />
        )}
      </div>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { productData } = this.state;
    const { images, addToBagDisabled, realPrice, salePrice, onSale, discountRate, videoThumbnail } = this.handleRender();
    // Customizable Area End
    return (
      // Customizable Area Start
      !this.state.skeletonLoader ? (
        <div className="product-description">
          <Helmet>
            <title>{productData.attributes.search_engine_listing?.title}</title>
            <meta name="description" content={productData.attributes.search_engine_listing?.description} />
          </Helmet>
          {this.renderNotActiveSnackBar()}
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ color: "var(--design-secondary-color)" }} />} aria-label="breadcrumb" className="products-description-category">
            <Link href="/" className="products-description-category-link">{configJSON.home}</Link>
            <Link href="/catalogue" className="products-description-category-link">{configJSON.allproducts}</Link>
            <Link
              className="products-description-category-link"
              href={`/catalogue/${productData.attributes.category.slug}`}
              onClick={() => this.handleBreadCrumbClick(productData.attributes.category.name, "")}
            >{productData.attributes.category.name}</Link>
            <Link
              className="products-description-category-link"
              href={`/catalogue/${productData.attributes.category?.slug}`
                + `/${productData.attributes.selected_sub_category?.slug}`}
              onClick={() => this.handleBreadCrumbClick(productData.attributes.category?.name, productData.attributes.selected_sub_category?.name)}
            >{productData.attributes.selected_sub_category?.name}</Link>
          </Breadcrumbs>
          <div className="content-wrapper">
            <div className="product-info-wrapper">
              <div className="product-images-area">
                {
                  images?.length > 0 ? (
                    <ImageGallery
                      renderItem={(item: ReactImageGalleryItem) => this.renderImageItem(item, videoThumbnail, false)}
                      renderThumbInner={(item: ReactImageGalleryItem) => this.renderImageItem(item, videoThumbnail, true)}
                      data-testid="image-gallery"
                      items={images.map(image => ({
                        original: image.url,
                        thumbnail: image.url
                      })) as ReactImageGalleryItem[]}
                      lazyLoad
                      thumbnailPosition="left"
                      showPlayButton={false}
                      showFullscreenButton={false}
                      showBullets={["sm", "md"].includes(this.state.screenSize)}
                      showThumbnails={["lg", "xl"].includes(this.state.screenSize)}
                      showNav={["lg", "xl"].includes(this.state.screenSize)}
                      renderLeftNav={(onClick, disabled) => (
                        <ImageGalleryNavIcon direction="left" onClick={onClick} disabled={disabled} />
                      )}
                      renderRightNav={(onClick, disabled) => (
                        <ImageGalleryNavIcon direction="right" onClick={onClick} disabled={disabled} />
                      )}
                    />
                  ) : (
                    <div className="no-image-available">
                      <CameraAltOutlined />
                      <span>{configJSON.noImageAvailable}</span>
                    </div>
                  )
                }
              </div>
              <div className="product-details-area">
                <h1 className="product-title">
                  {productData.attributes.name}
                </h1>
                <div className="product-price">
                  {this.state.currencySymbol} {Number(salePrice).toFixed(2)}
                </div>
                {
                  onSale && (
                    <div className="non-discounted-price-wrapper">
                      <span className="non-discounted-price">
                        {this.state.currencySymbol} {Number(realPrice).toFixed(2)}
                      </span>
                      <span className="discount-percentage">
                        {Number(discountRate)}% {configJSON.off}
                      </span>
                    </div>
                  )
                }
                {["lg", "xl"].includes(this.state.screenSize) && (
                  <>
                    <p className="description-title">
                      {configJSON.description}
                    </p>
                    <div
                      className="description-content"
                      dangerouslySetInnerHTML={{ __html: productData.attributes.description ?? "" }}
                    />
                  </>
                )}
                {
                  Array.isArray(productData.attributes.catalogue_variants) && (
                    <div className="variant-selection-area">
                      {
                        this.state.variantOptions.map((variantOption, index) => (
                          <VariantPropertySelector
                            data-testid={"variant-property-selector-" + index}
                            key={variantOption.id}
                            title={variantOption.name}
                            options={variantOption.optionsList}
                            value={variantOption.optionsList.find(option => option.isSelected)?.value ?? ''}
                            onChange={event => this.handleOptionChange(variantOption, event.target.value as string)}
                            onOpen={() => this.handleOptionClick(variantOption)}
                          />
                        ))
                      }
                    </div>
                  )
                }

                <div className="buttons-wrapper">
                  {addToBagDisabled ?
                    <p className="out-of-stock">{configJSON.outofStock}</p>
                    :
                    <>
                      {this.renderAddToBag()}
                    </>
                  }
                  <div className="hide-div-element" style={{ width: 56 }} />
                </div>

                {["sm", "md"].includes(this.state.screenSize) && (
                  <>
                    <h4 className="description-title">
                      {configJSON.description}
                    </h4>
                    <div
                      className="description-content"
                      dangerouslySetInnerHTML={{ __html: productData.attributes.description ?? "" }}
                    />
                  </>)}

                {productData.attributes.extra_infos.map(info => (
                  <div className="accordions-wrapper">
                    <Accordion square elevation={0}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ color: "var(--design-secondary-color)", fontFamily: "var(--design-body-text-font)" }} expandIcon={<ExpandMoreIcon style={{
                        color: "var(--design-secondary-color)"
                      }} />}>
                        {info.title}
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0, borderTop: "1px solid #E2E8F0", borderBottom: 'none' }}>
                        <p key={info.id} style={{ display: "flex", alignItems: "center", flexWrap: "wrap", wordBreak: "break-word" }}>
                          <span dangerouslySetInnerHTML={{ __html: info.description }} />
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {productData.attributes.video_url && (
            <Dialog
              open={this.state.videoModalOpen}
              onClose={this.handleVideoModal}
              maxWidth="md"
              fullWidth
            >
              <div className="iframe-wrapper">
                <iframe
                  src={this.getYoutubeEmbeddedUrl(productData.attributes.video_url)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={`Watch ${productData.attributes.name}`}
                  className="iframe-video"
                />
              </div>
            </Dialog>
          )}
        </div>
      ) : (
        <div className="product-description">
          <div className="skeleton-container">
            <div className="content-wrapper">
              {this.renderSkeleton()}
            </div>
          </div>
        </div>
      )
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default ProductDescription;
// Customizable Area End